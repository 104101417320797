import React, { Suspense, useEffect, useState } from 'react';
import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import theme from './theme';
import { init, send } from '@emailjs/browser';
import './i18n';
import DiagnosticsPage from './pages/_app';
import TerminalPage from './pages/terminal';
import { SerialHelper } from './utils/serial';

export const DeviceContext = React.createContext();

function App() {
  const [formData, setFormData] = useState({});
  const [device, setDevice] = useState();
  const [report, setReport] = useState();

  const formatReport = () => {
    const gHW = report.find(c => c.command === 'gHW!');
    const gRA = report.find(c => c.command === 'gRA!');
    const gBK = report.find(c => c.command === 'gBK!');
    const gBA = report.find(c => c.command === 'gBA!');
    const gIF = report.find(c => c.command === 'gIF!');
    const sFN = report.find(c => c.command === 'sFN!100!');
    const informations = SerialHelper.extractInformations(gIF);

    const hardware = SerialHelper.extractHardware(gHW);
    const results = JSON.stringify(SerialHelper.extractResults(gRA));
    const iBA = SerialHelper.normalizeBA(gBA);
    const iBK = SerialHelper.normalizeBA(gBK);
    const iFN = SerialHelper.normalizeFN(sFN);
    const temperature = informations.temperature;

    let formattedReport = {};
    if (hardware.error === undefined && results.error === undefined) {
      formattedReport = {
        hardware: JSON.stringify({ ...hardware }),
        serialNumber: hardware.hardware.equipmentHardware.e2prom.serialNumber,
        results: results,
        recipe: `${hardware.hardware.equipmentHardware.e2prom.lastRecipeUsed.name} - Revision ${hardware.hardware.equipmentHardware.e2prom.lastRecipeUsed.revision}`,
        iFN: iFN,
        gBK: iBK,
        gBA: iBA,
        firmware: informations.hardware,
        //gIF: temperature,
      };
    } else {
      formattedReport = {
        hardware: JSON.stringify({ ...hardware }),
        serialNumber: informations.serialNumber,
        results: results,
        recipe: informations.hardware,
        iFN: iFN,
        gBK: iBK,
        gBA: iBA,
        firmware: informations.hardware,
        //gIF: temperature,
      };
    }

    return formattedReport;
  };

  const handleReportSend = async () => {
    init('user_sGV43Tskc4swG0YMjLbJI');
    const formattedReport = formatReport();
    await send('service_58v2bpq', 'template_99ap3nw', {
      ...formData,
      ...formattedReport,
    });
  };
  return (
    <ChakraProvider theme={theme}>
      <DeviceContext.Provider value={[device, setDevice]}>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path=""
                element={
                  <DiagnosticsPage
                    setFormData={setFormData}
                    setReport={setReport}
                    handleReportSend={handleReportSend}
                  />
                }
              />
              <Route exact path="/terminal" element={<TerminalPage />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </DeviceContext.Provider>
    </ChakraProvider>
  );
}

export default App;
