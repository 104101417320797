import { Button, HStack, Input } from '@chakra-ui/react';
import React, { useState } from 'react';

const DeviceInput = ({ writer }) => {
  const [message, setMessage] = useState('');
  const handleSend = () => {
    const encoder = new TextEncoder();
    writer.write(encoder.encode(message + '\n'));
    setMessage('');
  };

  return (
    <HStack w={'75%'}>
      <Input
        value={message}
        onChange={e => setMessage(e.target.value)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
        border="solid 1px"
        borderColor={'gray.400'}
        placeholder="Entrez votre commande"
      />
      <Button colorScheme={'blue'} onClick={handleSend}>
        Envoyer
      </Button>
    </HStack>
  );
};
export default DeviceInput;
