import { Box, Center, HStack, Input, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

const Terminal = ({ data }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <Box
      p={2}
      rounded="md"
      w="100%"
      minH={'700px'}
      maxH={'700px'}
      overflowY="auto"
      border="solid 1px"
      borderColor={'gray.400'}
      backgroundColor="black"
      scrollBehavior={'auto'}
      color="whiteAlpha.800"
    >
      {data.map((line, index) => (
        <Text textAlign={'left'} key={index}>
          {line}
        </Text>
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default Terminal;
