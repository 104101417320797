import { Box, Center, Grid, VStack } from '@chakra-ui/react';
import React from 'react';
import Index from '.';
import { Logo } from '../Logo';

const DiagnosticsPage = ({ setFormData, handleReportSend, setReport }) => {
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <VStack spacing={0}>
          <Logo pointerEvents="none" />
          <Center pt={8} w="100%">
            <Index
              setFormData={setFormData}
              handleReportSend={handleReportSend}
              setReport={setReport}
            />
          </Center>
        </VStack>
      </Grid>
    </Box>
  );
};

export default DiagnosticsPage;
