import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceContext } from '../../App';

const ConnectButton = ({ onConnect }) => {
  const [device, setDevice] = React.useContext(DeviceContext);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setLoading(true);
    navigator.serial
      .requestPort({
        filters: [{ usbProductId: 32858, usbVendorId: 9025 }],
      })
      .then(async port => {
        await port.open({ baudRate: 115200, dataBits: 8, stopBits: 1 });
        setDevice(port);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (device) onConnect();
  }, [device]);

  return (
    <Button
      colorScheme={'blue'}
      disabled={device}
      onClick={handleClick}
      isLoading={isLoading}
    >
      {device ? t('connectButton.connected') : t('connectButton.connect')}
    </Button>
  );
};

export default ConnectButton;
