/* eslint-disable no-undef */
import { Box, Center, Grid, VStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DeviceContext } from '../App';
import ConnectButton from '../components/DeviceManager/ConnectButton';
import DeviceInput from '../components/DeviceManager/DeviceInput';
import Terminal from '../components/DeviceManager/Terminal';
import { Logo } from '../Logo';

class LineBreakTransformer {
  constructor() {
    // A container for holding stream data until a new line.
    this.chunks = '';
  }

  transform(chunk, controller) {
    // Append new chunks to existing chunks.
    this.chunks += chunk;
    // For each line breaks in chunks, send the parsed lines out.
    const lines = this.chunks.split('\r\n');
    this.chunks = lines.pop();
    lines.forEach(line => controller.enqueue(line));
  }

  flush(controller) {
    // When the stream is closed, flush any remaining chunks out.
    controller.enqueue(this.chunks);
  }
}

const TerminalPage = ({}) => {
  const [data, setData] = useState([]);
  const [device] = useContext(DeviceContext);
  const [writer, setWriter] = useState();

  const onConnect = async () => {
    const textDecoder = new TextDecoderStream();
    const readableStreamClosed = device.readable.pipeTo(textDecoder.writable);
    const reader = textDecoder.readable.getReader();
    setWriter(device.writable.getWriter());

    // Listen to data coming from the serial device.
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        reader.releaseLock();
        break;
      }
      // value is a string.
      setData(data => [...data, value]);
    }

    const textEncoder = new TextEncoderStream();
    const writableStreamClosed = textEncoder.readable.pipeTo(device.writable);

    reader.cancel();
    await readableStreamClosed.catch(() => {
      /* Ignore the error */
    });

    writer.close();
    await writableStreamClosed;

    await port.close();
  };

  useEffect(() => {
    const test = async () => {
      await onConnect();
    };
    if (device && device.readable) {
      test();
    }
  }, [device]);

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <VStack spacing={0}>
          <Logo pointerEvents="none" />
          <Center pt={8} w="100%">
            <VStack w={'100%'}>
              <ConnectButton onConnect={() => {}} />
              <Terminal data={data} />
              <DeviceInput writer={writer} />
            </VStack>
          </Center>
        </VStack>
      </Grid>
    </Box>
  );
};

export default TerminalPage;
