const _re = [
  {
    command: 'gIF!',
    data: 'iSS!1!iIF!0!29.77!35.00!30.00!60000!15000!1!1!1!1!0!20210625_161000!2011-2158!iTI!29.77!0!29.31!',
  },
  {
    command: 'gHW!',
    data: 'iHW!{"equipmentHardware":{"e2prom":{"type":"AMLOS","hardwareRevision":43,"serialNumber":"2011-2158","friendlyName":"Table","room":"room","ownedBy":"ownedby","temperatureGain":0.985,"temperatureOffset":0.9,"numberOfMeasure":53,"loopingMemory":true,"lastRecipeUsed":{"name":"LPX_COVID-19","revision":1}}}}!iTI!29.77!0!29.31!',
  },
  {
    command: 'gRE!200',
    data: 'iRE!{"result":{"isoTimeStamp":"2022-02-25T13:55:58","firmware":"20210625_161000","equipmentHardware":{"e2prom":{"type":"AMLOS","hardwareRevision":43,"serialNumber":"2011-2158","friendlyName":"Table","room":"room","ownedBy":"ownedby","temperatureGain":0.985,"temperatureOffset":0.9,"numberOfMeasure":52,"loopingMemory":true,"lastRecipeUsed":{"name":"LPX_COVID-19","revision":1}}},"softwareRevision":"","gpsCoordinate":"","result":{"isoStartTimeStamp":"2022-02-25 13:50:44","heatingTime":78442,"coolingTime":44534,"lampDuration":59906,"sampleName":"qsd;sdq","sejourID":"","measurements":{"baseline":[[426,462,754,837,711,832,638,2539,26],[421,454,749,831,703,822,633,2519,26],[419,452,746,829,700,818,631,2510,25]],"lamp":[[429,463,760,842,712,837,646,2575,26],[422,455,750,832,703,824,635,2531,26],[417,451,745,826,698,816,629,2505,25],[416,448,743,824,695,812,626,2494,25]]},"fom":0.001918,"coefficients":[0.000935],"IterationsCount":5,"diagnosis":false,"inhibition":false,"rms":0.00195,"error":0}}}!',
  },
];

export class SerialHelper {
  static extractInformations(response) {
    const informations = {
      hardware: response.data.split('!').filter(s => s.includes('_'))[0],
      serialNumber: response.data.split('!').filter(s => s.includes('-'))[0],
      temperature: response.data.split('!')[2],
    };

    return informations;
  }

  static extractHardware(response) {
    try {
      const hardware = JSON.parse(
        response.data.substring(
          response.data.indexOf('!{"') + 1,
          response.data.lastIndexOf('}}!') + 2
        )
      );

      return { hardware };
    } catch (e) {
      return { error: e, hardware: response.data };
    }
  }

  static extractResults(response) {
    try {
      const results = JSON.parse(
        response.data.substring(
          response.data.indexOf('!{"') + 1,
          response.data.lastIndexOf(']}!') + 2
        )
      );

      return results;
    } catch (e) {
      return { error: e, results: response.data };
    }
  }

  static normalizeBA(response) {
    try {
      let string = response.data.split('iTI')[0].replace(/[^0-9,]/g, '');
      const iBA = string;
      return iBA;
    } catch (e) {
      return response.data;
    }
  }

  static normalizeBK(response) {
    try {
      let string = response.data.split('iTI')[0].replace(/[^0-9,]/g, '');
      const iBK = string;
      return iBK;
    } catch (e) {
      return response.data;
    }
  }

  static normalizeFN(response) {
    try {
      let string = response.data.split('iTI')[0].replace(/[^0-9,]/g, '');
      const iFN = string;
      return iFN;
    } catch (e) {
      return response.data;
    }
  }
}
