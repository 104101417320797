import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Fade,
  Icon,
  Spinner,
  Text,
  Button,
  Center,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceContext } from '../../App';
import { BsFillPlayFill } from 'react-icons/bs';

const timer = ms => new Promise(res => setTimeout(res, ms));

const Diagnostics = ({ nextStep, setReport }) => {
  const [device] = React.useContext(DeviceContext);
  const [isRunning, setRunning] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const readDeviceStream = async () => {
    return new Promise(async (resolve, reject) => {
      const Decoder = new TextDecoder();
      const reader = device.readable.getReader();
      let readableValue;
      const _data = [];
      try {
        while (true) {
          const { value, done } = await reader.read();
          readableValue = Decoder.decode(value);
          if (done) {
            break;
          }

          readableValue = readableValue.trim();
          readableValue = readableValue.replace(/\r\n/g, '');
          _data.push(readableValue);
          const lastData = _data[_data.length - 1];
          if (lastData.slice(lastData.length - 1) === '!') {
            break;
          }
        }
      } catch (error) {
        reject(error);
      } finally {
        reader.releaseLock();
      }
      resolve(_data.join(''));
    });
  };

  const handleDiagnostics = async () => {
    setRunning(true);
    const commands = [
      { input: 'gIF!', output: 'iIF!' },
      { input: 'gHW!', output: 'iHW!' },
      { input: 'sFN!100!', output: 'iFN!' },
      { input: 'gRC!', output: 'iRC!' },
      { input: 'gRA!', output: 'iRA!' },
      { input: 'gBK!', output: 'iBA!' },
      { input: 'gBA!', output: 'iBA!' },
    ];

    const encoder = new TextEncoder();
    const writer = device.writable.getWriter();
    for (const command of commands) {
      writer.write(encoder.encode(command.input + '\n'));
      if (command.input === 'sST!') {
        await timer(120000); // 120000
      } else {
        await timer(3000);
      }
      let _data = await readDeviceStream();

      while (!_data.includes(command.output)) {
        _data = await readDeviceStream();
        if (_data.includes(command.output)) {
          break;
        }
      }
      setData(data => [...data, { command: command.input, data: _data }]);
    }
    setRunning(false);
    setIsDone(true);
  };
  useEffect(() => {
    if (isDone && data.length > 0) {
      setReport(data);
      setTimeout(() => {
        nextStep();
      }, 2000);
    }
  }, [isRunning, isDone, data]);

  return (
    <Box>
      {!isRunning && !isDone && (
        <Fade in={true}>
          <Center>
            <Text py={4} fontWeight={500}>
              {t('diagnostics.verify')}
            </Text>
          </Center>
          <Button
            disabled={!device}
            onClick={handleDiagnostics}
            colorScheme="blue"
            rightIcon={<BsFillPlayFill />}
          >
            {t('diagnostics.run')}
          </Button>
        </Fade>
      )}
      {isRunning && (
        <Fade in={true}>
          <Spinner mt={8} color="orange" size="xl" />
          <Text mt={8}>{t('diagnostics.running')}</Text>
        </Fade>
      )}
      {!isRunning && isDone && (
        <>
          <Fade in={true}>
            <CheckIcon w={8} h={8} color="green.400" />
          </Fade>
          <Text mt={8}>{t('diagnostics.done')}</Text>
        </>
      )}
    </Box>
  );
};

export default Diagnostics;
