import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyForm = ({ onFormSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        company: '',
        email: '',
        phoneNumber: '',
        name: '',
        consent: false,
      }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          onFormSubmit(values);
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      {props => (
        <Form>
          <Field name="company">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.company && form.touched.company}
              >
                <FormLabel htmlFor="company">{t('form.company')}</FormLabel>
                <Input
                  {...field}
                  required
                  id="company"
                  placeholder={t('form.company')}
                />
                <FormHelperText>{form.errors.company}</FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name="name">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                <FormLabel htmlFor="name">{t('form.name')}</FormLabel>
                <Input
                  {...field}
                  required
                  id="name"
                  placeholder={t('form.name')}
                />
                <FormHelperText>{form.errors.name}</FormHelperText>
              </FormControl>
            )}
          </Field>
          <SimpleGrid columns={2} gap={4} pb={4}>
            <GridItem>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">{t('form.mail')}</FormLabel>
                    <Input
                      required
                      {...field}
                      id="email"
                      placeholder={t('form.mail')}
                    />
                    <FormHelperText>{form.errors.email}</FormHelperText>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.phoneNumber && form.touched.phoneNumber
                    }
                  >
                    <FormLabel htmlFor="phoneNumber">
                      {t('form.phoneNumber')}
                    </FormLabel>
                    <Input
                      required
                      {...field}
                      id="phoneNumber"
                      placeholder={t('form.phoneNumber')}
                    />
                    <FormHelperText>{form.errors.phoneNumber}</FormHelperText>
                  </FormControl>
                )}
              </Field>
            </GridItem>
          </SimpleGrid>
          <Field name="consent" required={true}>
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.consent && form.touched.consent}
              >
                <Checkbox
                  id="consent"
                  {...field}
                  colorScheme="orange"
                  isRequired
                  required
                  borderColor={'blue.400'}
                >
                  <Text fontWeight={500} textAlign="justify">
                    {t('form.consent')}
                  </Text>
                </Checkbox>
                <FormHelperText>{form.errors.consent}</FormHelperText>
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            colorScheme="blue"
            type="submit"
            rightIcon={<ChevronRightIcon />}
            isLoading={props.isSubmitting}
          >
            {t('form.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
