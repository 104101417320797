import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceContext } from '../App';
import ConnectButton from '../components/DeviceManager/ConnectButton';
import Diagnostics from '../components/Diagnostics';
import CompanyForm from '../components/Form';

export const Index = ({
  setFormData,
  handleReportSend,
  setReport,
  ...props
}) => {
  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const [device, setDevice] = React.useContext(DeviceContext);

  const onFormSubmit = values => {
    setFormData(values);
    nextStep();
  };

  const onDeviceConnect = () => {
    nextStep();
  };

  const { t } = useTranslation();

  const steps = [
    {
      label: t('diagnostics.steps.form'),
      component: <CompanyForm onFormSubmit={onFormSubmit} />,
    },
    {
      label: t('diagnostics.steps.device'),
      component: <ConnectButton onConnect={onDeviceConnect} />,
    },
    {
      label: t('diagnostics.steps.diagnostics'),
      component: <Diagnostics nextStep={nextStep} setReport={setReport} />,
    },
  ];

  useEffect(() => {
    const report = async () => {
      await handleReportSend();
    };
    if (activeStep === steps.length) {
      report();
    }
  }, [activeStep]);

  const canSerial = () => {
    return 'serial' in navigator;
  };

  const resetSteps = () => {
    setStep(1);
    setReport(null);
    setDevice(null);
  };

  if (canSerial()) {
    return (
      <VStack w="100%" h="100%">
        <Text fontSize="xl" fontWeight="bold" pb={8}>
          {t('app.title')}
        </Text>
        <Flex flexDir="column" width="60%" h="100%">
          <Steps activeStep={activeStep}>
            {steps.map(({ label, component }) => (
              <Step label={label} key={label}>
                <ScaleFade offsetY={'-200px'} in={true}>
                  <Box p={4} mt={12}>
                    {component}
                  </Box>
                </ScaleFade>
              </Step>
            ))}
          </Steps>
        </Flex>
        {activeStep === steps.length && (
          <Center>
            <Flex mt={12} p={4} w="100%">
              <VStack gap={4}>
                <Text>{t('diagnostics.reportSent')}</Text>
                <Button
                  onClick={resetSteps}
                  colorScheme="blue"
                  rightIcon={<RepeatIcon />}
                >
                  {t('diagnostics.anotherDevice')}
                </Button>
              </VStack>
            </Flex>
          </Center>
        )}
      </VStack>
    );
  } else {
    return (
      <VStack>
        <Text fontSize="xl" fontWeight="bold" pb={8}>
          {t('app.title')}
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          {t('serial.unsupported')}
        </Text>
      </VStack>
    );
  }
};
export default Index;
